

export default [
  {
      path: '/home',
      component: "__base",
      children: [
        {
          // module: "UCP",
          path: "dashboard",
          component: "Dashboard.vue",
          meta: { title: "Unified Controllership Platform", noauth: true },          
        },
        {
          // module: "UCP",
          path: "profile",
          component: "Profile.vue",
          meta: { title: "Profile Info" },        
        },
        {
          // module: "UCP",
          path: "Locations",
          component: "Locations.vue",
          meta: { title: "Locations" },        
        },
        {
          // module: "UCP",
          path: "*",
          component: "",
        },
      ],
  },
]

